<template>
  <div class="setPrice-container">
    <Top></Top>
    <Fix></Fix>
    <div class="setPrice-container-top">
      <div>
        <div class="setPrice-div1">
          <img :src="require('/static/images/person-top.png')" alt="">
        </div>
        <div class="setPrice-div2"></div>
        <div class="setPrice-div3" @click="toagencyCeter">代理人中心</div>
      </div>
    </div>
    <div class="setPrice-center">
        <div class="setPrice-ceter-title">
          <span>设置服务价格</span>
        </div>
        <div class="setPrice-middle">
          <div class="setPrice-center-show">
            <span></span><span>费用项目说明</span>
          </div>
          <div>
            <span>协议价格：</span><span>代理人与本平台的签单合作价。</span>
          </div>
          <div class="setPrice-center-service">
            <span>服务价格：</span><span>代理人为客户提供服务时，向客户主张的价格。代理人可以在签单时，在市场参考价范围内自主定价；也可以在本页面设置每项服务的服务价格，设置好后，服务价格会自动显示在代理人的支付页面。</span>
          </div>
          <div>
            <span>服务费用：</span><span>本平台会收取每单服务价格的8%作为平台运营费用。</span>
          </div>
        </div>
        <div class="setPrice-ceter-detail">
            <div class="setPrice-center-show" :class="changepriceStatus">
               <div class="setPrice-show-div1" @click="changeRisk('trade')">商标业务</div>
               <div class="setPrice-show-div2" @click="changeRisk('patent')">专利业务</div>
               <div class="setPrice-show-div3" @click="changeRisk('copyright')">版权业务</div>
          </div>
        </div>
        <div class="setPrice-ceter-list">
            <div class="list-box" v-for="(v,k) in list" :key='k'>
              <div class="list-title">
                <span>商品名称</span><span>{{v.goodsName}}</span>
              </div>
              <div class="list-detail">
                <div>
                  <span>协议价格</span>
                  <span>{{v.proxyPrice | numberFix2}}</span>
                </div>
                <div>
                  <span>市场参考价</span>
                  <span>{{v.orignialMin}}~{{v.orignialMax}}元，均价{{v.orignial}}元</span>
                </div>
                <div>
                  <span>服务价格</span>
                  <span>{{v.goodsUserdefinedAmt | numberFix2}}</span>
                </div>
                <div>
                  <span>服务费用</span>
                  <span>{{v.serCharge | numberFix2}}</span>
                </div>
                <div>
                  <span>预估收益</span>
                  <span>{{v.earning | numberFix2}}</span>
                </div>
                <div style="clear:both"></div>
              </div>
              <div class="list-setPrice" @click="set(v.goodsCode,v.orignialMin,v.orignialMax,v.goodsUserdefinedAmt)">设置服务价格</div>
            </div>

        </div>
    </div>
    <Foot></Foot>
    <div class="setPrice-chuang" v-show='chuangShow'>
      <div class="setPrice-form">
         <img :src="require('/static/images/cha.png')" alt="" @click="cha">
         <div class="form-div">
              <div class="set-title">设置服务价格</div>
              <div class="set-middle">
                <div>服务价格</div>
                <div>
                  <input type="text" v-model="servicePrice" onkeyup="value=value.replace(/[^\d]/g,'')">
                  <span>元</span>
                </div>
                <div class="set-range">在市场参考价{{range1}}-{{range2}}元范围内调整，需为整数。</div>
                <div class="set-buttom" @click="changePrice">确定</div>
              </div>
         </div>
       </div>

    </div>
  </div>
</template>

<script>
import Top from '../../ui/top.vue';
import Fix from '../../ui/fix.vue';
import Foot from '../../ui/foot.vue';
export default {
  name: 'setPrice',
  components: {
    Top,
    Foot,
    Fix
  },
  data() {
      return {
        servicePrice:'',
        range1:'',
        range2:'',
        chuangShow:false,
        list:[],
        goodsCode:'',
        changepriceStatus:"trade"
      };
    },
    methods: {
      tip(type,msg){
          return this.$message({
              type:type,
              message:msg,
              showClose:true,
              duration:1500
          })
      },
     toagencyCeter(){
         this.$router.push('/agency')
     },
     cha(){
       this.chuangShow =  false;
     },
     set(val1,val2,val3,val4){
       this.chuangShow =  true;
       this.goodsCode = val1,
       this.range1 = val2,
       this.range2 = val3;
       this.servicePrice = val4
     },
     changeRisk(val){
       this.changepriceStatus = val
       this.getData()
     },
     getData(){
      var goodsType = "";
         if(this.changepriceStatus == "trade"){
              goodsType = "1"
          }else if(this.changepriceStatus == "patent"){
              goodsType = "2"
          }else if(this.changepriceStatus == "copyright"){
              goodsType = "3"
          }
       this.$http.post(this.GLOBAL.new_url+'/goods/service/goodsUserDefined/goodsPrice/list',{
              userCode:this.$cookie.getCookie('u_id'),
              goodsType:goodsType
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                  // console.log(res)
              if(res.data.code == "200"){
                  this.list = res.data.data;
                  var _this=this;
                this.list.map(x=>{
                          _this.$set(x, 'earning', x.goodsUserdefinedAmt - x.proxyPrice - x.goodsUserdefinedAmt * 0.08);
                          if(x.earning<0){
                            x.earning = 0
                          }
                           _this.$set(x, 'serCharge', x.goodsUserdefinedAmt * 0.08);
                      })
              }
            })
     },
     changePrice(){
       if(this.servicePrice>this.range2||this.servicePrice<this.range1){
         this.tip('error','请输入市场参考价格范围内的价格')
       }else{
          this.$http.post(this.GLOBAL.new_url+'/goods/service/goodsUserDefined/goodsPrice/modify',{
              userCode:this.$cookie.getCookie('u_id'),
              goodsCode:this.goodsCode,
              goodsUserdefinedAmt:this.servicePrice
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                  // console.log(res)
              if(res.data.code == "200"){
                  this.chuangShow =  false;
                  this.getData();
              }
            })
       }
     }
  },
  filters:{
    numberFix2(number){
      if(isNaN(Number(number))) return number
      return number.toFixed(2)
    }
  },
  mounted(){
    this.getData()
  }
}

</script>

<style scoped>
.setPrice-container {
  background-color: #fafafa;
  z-index: 2;
  position: relative;
}
.setPrice-container-top{
  width: 100%;
  box-shadow: 0 4px 4px 0 rgba(231, 231, 231, 0.5);
  height: 96px;
  background: #ffffff;
  margin-bottom: 4px;
}
  .setPrice-container-top>div{
    width: 1000px;
    margin: 0 auto;
    height: 96px;
  }
  .setPrice-div1{
    width: 120px;
    height: 45px;
    margin-top: 33px;
    margin-right: 20px;
  }
  .setPrice-div1>img{
    margin-top: 3px;
  }
  .setPrice-div2{
    width: 2px;
    height: 36px;
    background-color: #EB5E00;
    margin: 30px 18px 0 0;
  }
  .setPrice-div3{
    font-size: 24px;
    margin-top: 31px;
    color: #EB5E00;
    cursor: pointer;
  }
  .setPrice-container-top>div>div{
    float: left;
  }
  .setPrice-center{
    width: 1080px;
    margin: 0 auto;
    background: #ffffff;
    padding: 30px 60px 0;
  }
  .setPrice-ceter-title{
    height: 32px;
    border-bottom:1px solid #D8D8D8;
  }
  .setPrice-ceter-title>span{
    font-size: 16px;
    margin-left: 4px;
    color: #000000;
  }
  .setPrice-middle{
    padding-left:4px;
  }
  .setPrice-middle>div>span{
    font-size: 14px;
  }
  .setPrice-middle>div>span:nth-of-type(1){
    margin-right: 10px;
  }
  .setPrice-middle>div>span:nth-of-type(2){
    font-weight: 400;
    color: #676767;
  }
  .setPrice-center-show{
    margin: 30px 0 0 0;
    height: 31px;
  }
  .setPrice-center-show>div{
    width: 80px;
    text-align: center;
    float: left;
    height: 31px;
    margin-right: 40px;
    cursor: pointer;
  }
  .setPrice-center-show>span:nth-of-type(1){
    display: inline-block;
    width: 4px;
    height: 14px;
    background: #EB5E00;
    margin-right: 10px;
    vertical-align: middle;
  }
  .setPrice-center-show>span:nth-of-type(2){
    font-size: 14px;
    color: #000!important;
    vertical-align: middle;
  }
  .trade .setPrice-show-div1,.patent .setPrice-show-div2,.copyright .setPrice-show-div3{
    border-bottom: 1px solid #333333;
  }
  .setPrice-center-service{
    margin: 16px 0;
  }
  .setPrice-center-service>span:nth-of-type(2){
    display: inline-block;
    width: 804px;
    height: 40px;
    vertical-align: top;
  }
  .list-box{
    padding: 30px 0;
    height: 140px;
    border-top:1px solid #D8D8D8;
  }
  .list-title{
    margin-bottom: 16px;
    font-size: 14px;
  }
  .list-title>span:nth-of-type(1){
    color: #000000;
  }
  .list-title>span:nth-of-type(2){
    color:#323232;
    margin-left: 24px;
  }
  .list-detail{
    overflow: hidden;
  }
  .list-detail>div{
    width: 335px;
    float: left;
    font-size: 14px;
    margin-bottom: 16px;
  }
  .list-detail>div>span:nth-of-type(1){
    color: #000000;
  }
  .list-detail>div>span:nth-of-type(2){
    color:#323232;
    margin-left: 24px;
  }
  .list-detail>div:nth-of-type(2){
    width: 410px;
  }
  .list-detail>div:nth-of-type(2)>span:nth-of-type(2){
    margin-left: 10px;
  }
  .list-setPrice{
    width: 124px;
    height: 30px;
    background: #EB5E00;
    cursor: pointer;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .setPrice-chuang{
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.4);
    z-index: 20;
    top: 0;
    left: 0;
  }
  .setPrice-form{
    width: 600px;
    height: 282px;
    background: #FFFFFF;
    position: absolute;
    top:50%;
    left: 50%;
    z-index: 30;
    transform: translate(-50%,-50%);
  }
  .setPrice-form>img{
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    height: 23px;
    width: 23px;
  }
  .form-div{
    width: 430px;
    position: absolute;
    top:30px;
    left: 88px;
  }
  .set-title{
    width: 96px;
    margin: 0 auto 30px;
    color: #323232;
    font-size: 16px;
  }
  .set-middle>div:nth-of-type(1){
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 400;
    color: #323232;
  }
  .set-middle>div:nth-of-type(2)>input{
    width: 384px;
    height: 30px;
    outline: none;
    margin-right: 10px;
    padding-left: 12px;
    border-radius: 3px;
    border: 1px solid #D8D8D8;
  }
  .set-middle>div:nth-of-type(2)>span{
    font-size: 14px;
  }
  .set-range{
    font-size: 14px;
    margin: 10px 0 40px;
    font-weight: 400;
    color: #000000;
  }
  .set-buttom{
    width: 108px;
    height: 36px;
    background: #EB5E00;
    border-radius: 4px;
    text-align: center;
    line-height: 36px;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 14px;
    margin: 0 auto;
  }
</style>

